import React from "react";
import { useLocation } from "react-router-dom";
import MfaLoginForm from "Components/Login/MfaLoginForm";
import MuiThemeProvider from "@mui/material/styles/ThemeProvider";
import IntroGlobe from "Components/Login/IntroGlobe";
import LoginForm from "Components/Login/LoginForm";
import ForgotLoginForm from "Components/Login/ForgotLoginForm";
import ResetPasswordForm from "Components/Login/ResetPasswordForm";
import { MyTheme } from "Utils/UnixiTheme";
import { useState, useLayoutEffect } from "react";

function LoginPage({ formType }) {
  const location = useLocation();
  const [animateGlobe, setAnimateGlobe] = useState({});
  const [scaling, setScaling] = useState(1.0);
  // const lastLocation = location.state?.from || '';

  useLayoutEffect(() => {
    function updateSize() {
      const standardWidth = 1920;
      const currentWidth = window.innerWidth;
      const scale = currentWidth / standardWidth;
      setScaling(scale);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  let token = "";
  if (formType === "reset") {
    const searchParams = new URLSearchParams(location.search);
    token = searchParams.get("token");
  }

  return (
    <MuiThemeProvider theme={MyTheme}>
      <div style={{ zoom: scaling }}>
        <div
          style={{
            position: "absolute",
            right: "-150px",
            top: "-7.5vh",
            paddingBottom: "100px",
            maxHeight: "100vh",
          }}
        >
          <IntroGlobe animateGlobe={animateGlobe} />
        </div>

        <div style={{ position: "absolute", left: "10%", top: 0, bottom: 0 }}>
          {formType === "mfa" ? (
            <MfaLoginForm />
          ) : formType === "forgot" ? (
            <ForgotLoginForm />
          ) : formType === "reset" && token ? (
            <ResetPasswordForm token={token} />
          ) : (
            <LoginForm
              animateGlobe={animateGlobe}
              setAnimateGlobe={setAnimateGlobe}
              // fromLocation={lastLocation}
            />
          )}
        </div>
      </div>
    </MuiThemeProvider>
  );
}

export default LoginPage;
