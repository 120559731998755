import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import logo from 'Assets/unixi_logo.png';
import whiteLogo from 'Assets/unixi_logo_white.svg';
import global from '../Global.module.css';
import styles from './LoginForm.module.css';
import 'react-toastify/dist/ReactToastify.css';

import { faLock, faRightToBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';
import { toast } from 'react-toastify';
import { useAuthenticate } from 'Services/Authentication';
import { getProfileData, useLogin, useLoginGoogle } from 'Services/LoginAPI';
import { Toolbar, Tooltip } from '@mui/material';

let isUnixiExtensionAllowed = false;
let unixiExtensionEnv = null;
const serverName = window.location.host;

const unixiExtensionExistsWithinThisPageAndReadytoGo = JSON.parse(
    localStorage.getItem(`${serverName}-managementPortalWithUnixi`)
);

let isUnixiExtensionActive = !!unixiExtensionExistsWithinThisPageAndReadytoGo;

isUnixiExtensionAllowed = !!unixiExtensionExistsWithinThisPageAndReadytoGo?.allowed;
unixiExtensionEnv = unixiExtensionExistsWithinThisPageAndReadytoGo?.env;

let managementPortalWithUnixi = {
    allowed: isUnixiExtensionAllowed,
    env: unixiExtensionEnv
};

export default function LoginForm(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [extensionActive, setExtensionActive] = useState(isUnixiExtensionActive);
    const [userCode, setUserCode] = useState(null);
    const [rememberMe, setRememberMe] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        // Send message to extension to get user code
        window.postMessage({ type: `${serverName}-GET_USER_DATA` }, '*');

        // Listen for the response from the content script
        const handleExtensionMessage = (event) => {
            if (event.data && event.data.type === `${serverName}-USER_DATA_RESPONSE`) {
                const user_code = event.data.user_code;
                setUserCode(user_code);
            }
        };

        window.addEventListener('message', handleExtensionMessage);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('message', handleExtensionMessage);
        };
    }, []);

    function CompleteLogin(response) {
        useAuthenticate(response.user, response.tenant);
        const LandingPage = response.tenant.landing_page;
        props?.animateGlobe?.animation();

        setTimeout(() => CompleteLogin2(LandingPage), 2000, response);
    }

    function CompleteLogin2(LandingPage) {
        const redirectPath = localStorage.getItem('redirectPath') || '/';
        console.log(redirectPath);
        localStorage.removeItem('redirectPath'); // Clear the saved path
        if (redirectPath === '/') {
            window.location.href = (LandingPage ? `/unixi-management-portal/app/${LandingPage}` : '/unixi-management-portal/app/dashboard');
        } else if (redirectPath.startsWith('/unixi-management-portal')) {
            window.location.href = (`${redirectPath}`);
        } else {
            window.location.href = (`/unixi-management-portal${redirectPath}`);
        }
    }

    function CompleteLoginGoogle(response) {
        var credential = response;
        useLoginGoogle(credential)
            .then((response) => {
                if (response && response.status) {
                    if (response.page === 'login-mfa') {
                        navigate('login-mfa');
                    } else {
                        CompleteLogin(response);
                    }
                } else if (response) {
                    toast.error(response.info);
                } else {
                    toast.error('Communication error occurred.');
                }
            })
            .catch((error) => {
                toast.error(error.toString());
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleMicrosoftLogin = async () => {
        window.addEventListener('message', async (event) => {
            let serverName = window.location.origin;

            console.log(event.data);
            if (event.origin !== serverName) {
                //RV please check only this because we have a different port
                toast.error('ERROR: wrong origin');
                return;
            }

            if (event.data.success === 'true') {
                let data = await getProfileData();
                if (data.status) {
                    CompleteLogin(data);
                } else {
                    toast.error('Could not authenticate, please try again.');
                }
            } else {
                toast.error(`ERROR: ${event.data.info}`);
            }
        });
    };

    const handleGoogleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: (response) => {
            CompleteLoginGoogle(response);
        }
    });

    function DoLogin(e, withUnixi = false) {
        setLoading(true);
        e.preventDefault();

        // Call useLogin and then based on the response, set the rememberedLogin flag
        useLogin(username, password, withUnixi, userCode, rememberMe && userCode != null)
            .then((response) => {
                if (response && response.status) {
                    if (rememberMe && userCode != null) {
                        window.postMessage(
                            {
                                type: `${managementPortalWithUnixi.env}-SUCCESSFUL_REMEMBER_ME_LOGIN`,
                                management_portal_with_unixi: {
                                    allowed: true,
                                    env: managementPortalWithUnixi.env
                                }
                            },
                            '*'
                        );
                    }
                    localStorage.setItem(
                        `${serverName}-managementPortalWithUnixi`,
                        JSON.stringify({
                            allowed:
                                managementPortalWithUnixi.allowed ||
                                (rememberMe && userCode != null),
                            env: managementPortalWithUnixi.env
                        })
                    );
                    if (response.page === 'login-mfa') {
                        // localStorage.setItem(
                        //     'fromLocation',
                        //     props.fromLocation || '/app/dashboard'
                        // );
                        navigate('login-mfa');
                    } else {
                        CompleteLogin(response);
                    }
                } else if (response) {
                    toast.error(response.info);
                    if (withUnixi && !userCode) {
                        localStorage.removeItem(`${serverName}-managementPortalWithUnixi`);
                        setExtensionActive(false);
                        managementPortalWithUnixi.allowed = false;
                    }
                } else {
                    toast.error('Communication error occurred.');
                }
            })
            .catch((error) => {
                toast.error(error.toString());
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // Conditionally render the login form based on extensionActive and managementPortalWithUnixi state
    return (
        <div className={styles.container}>
            <br />

            <div>
                <img src={logo} className={styles.logo} alt='logo' />
            </div>

            <div className={styles.login_message}>
                {extensionActive && managementPortalWithUnixi.allowed
                    ? 'Please press below to sign in with UNIXi'
                    : 'Please sign in to continue'}
                <div className={styles.error_message}> </div>
            </div>
            <br />

            <form className={styles.form}>
                {/* Conditionally render username and password fields only if Unixi is not enabled */}
                {!extensionActive || !managementPortalWithUnixi.allowed ? (
                    <>
                        <TextField
                            onChange={(e) => setUsername(e.target.value)}
                            id='outlined-basic'
                            label={
                                <>
                                    {' '}
                                    <FontAwesomeIcon icon={faUser} /> Username{' '}
                                </>
                            }
                            variant='outlined'
                            className={[global.input, styles.input].join(' ')}
                        />{' '}
                        <br />
                        <TextField
                            onChange={(e) => setPassword(e.target.value)}
                            id='outlined-basic'
                            label={
                                <>
                                    {' '}
                                    <FontAwesomeIcon icon={faLock} /> Password{' '}
                                </>
                            }
                            variant='outlined'
                            className={[global.input, styles.input].join(' ')}
                            type='password'
                        />{' '}
                        <br />
                        {!managementPortalWithUnixi.allowed && (
                            <Tooltip
                                title={!userCode ? "Remember Me is only available with UNIXi extension" : "This will allow you to login faster while using UNIXi extension"}
                                placement='top'
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={!userCode}
                                            checked={rememberMe && userCode != null}
                                            onChange={(e) => setRememberMe(e.target.checked)}
                                            color='primary'
                                        />
                                    }
                                    label='Remember Me'
                                    className={styles.rememberMeLabel}
                                />
                            </Tooltip>
                        )}
                        <br />
                    </>
                ) : null}

                {extensionActive && managementPortalWithUnixi.allowed ? (
                    <Button
                        onClick={(e) => DoLogin(e, true)}
                        type='submit'
                        variant='contained'
                        color='error'
                        className={styles.submit_button_with_unixi}
                        disabled={loading}
                    >
                        {'Login with'}
                        &nbsp;
                        {loading ? (
                            <Box
                                sx={{
                                    width: 65,
                                    display: 'inline-block'
                                }}
                            >
                                <CircularProgress disableShrink size={18} />
                            </Box>
                        ) : (
                            <img src={whiteLogo} className={styles.logoButton} alt='UNIXi Logo' />
                        )}
                    </Button>
                ) : (
                    <Button
                        onClick={(e) => DoLogin(e)}
                        type='submit'
                        variant='contained'
                        color='error'
                        className={styles.submitButton}
                        disabled={loading}
                    >
                        {'Login'}
                        &nbsp;
                        {loading ? (
                            <CircularProgress disableShrink size={18} />
                        ) : (
                            <FontAwesomeIcon icon={faRightToBracket} />
                        )}
                    </Button>
                )}

                <br />
                <br />

                {/* SSO Login options remain visible in both cases */}
                <div style={{color: 'white'}}>Login via SSO</div>
                <br />
                <GoogleLoginButton
                    className={[styles.socialLogin]}
                    size='40px'
                    onClick={handleGoogleLogin}
                />
                {/* <MicrosoftLoginButton
                    className={[styles.socialLogin]}
                    size='40px'
                    onClick={handleMicrosoftLogin}
                /> */}
            </form>

            <div className={styles.linkHolder}>
                <div style={{ flex: 1, height: '1px', backgroundColor: 'white' }} />
                {extensionActive && managementPortalWithUnixi.allowed ? (
                    <div
                        className={styles.link}
                        onClick={() => {
                            setExtensionActive(false);
                            setRememberMe(false);
                        }}
                    >
                        Login With Password
                    </div>
                ) : (
                    <Link to='/forgot-password' className={styles.link}>
                        Reset Password
                    </Link>
                )}
                <div style={{ flex: 1, height: '1px', backgroundColor: 'white' }} />
            </div>
        </div>
    );
}
